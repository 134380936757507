html,
body {
  background-color: #ffffff !important;
  background: #ffffff !important;
}
@viewport {
  orientation: portrait;
}

.App {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}
.flex-space-header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: space-between;
}

.menu-item {
  width: 100% !important;
}

html {
  -webkit-tap-highlight-color: transparent;
}
ul:has(.menu-item) {
  width: 60vw !important;
}

div:has(.menu-item) {
  max-height: 50vh !important;
  height: 50vh !important;
  max-width: 70vw !important;
  width: 70vw !important;
  min-width: unset !important;
}

@keyframes homeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.section-h {
  background-color: #f0f0f0;
  height: 55%;
  /* height: calc(100vh - 350px);  */
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.section-h::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.28);
}

.section {
  background-color: #f0f0f0;
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.section-e {
  background-color: #f0f0f0;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

.btn-cls-google {
  height: 52px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  border: transparent;
  width: 100%;
  background: black;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16),
    inset 0px -2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 32px;
  font-family: "Red Hat Display bold";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  cursor: pointer !important;
}

.btn-cls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  border: transparent;
  width: 224px;
  background: linear-gradient(202.4deg, #f5448f -43.81%, #263aa7 182.55%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16),
    inset 0px -2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 32px;
  font-family: "Red Hat Display bold";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 20px 0;
  cursor: pointer !important;
}

.btn-cls-clicked {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  border: transparent;
  width: 224px;
  background: linear-gradient(202.4deg, #f5448f -43.81%, #263aa7 182.55%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16),
    inset 0px -2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 32px;
  font-family: "Red Hat Display bold";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  opacity: 0.5;
  margin: 20px 0;
  cursor: pointer !important;
}

.btn-cls-disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  border: transparent;
  width: 224px;
  background: linear-gradient(202.4deg, #f5448f -43.81%, #263aa7 182.55%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16),
    inset 0px -2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 32px;
  font-family: "Red Hat Display bold";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  opacity: 0.5;
  margin: 20px 0;
  cursor: pointer !important;
}

.gmail {
  height: 100px;
}

.error {
  position: relative;
  bottom: 15%;
  right: 5%;
}

.input-price > div::before {
  border-bottom: 0px;
}

.side-padding-h {
  padding: 0 20px 20px;
}

.side-padding-e {
  padding: 0 20px 20px;
  /* position: fixed;
  bottom: 0; */
}

.side-padding {
  padding: 0 20px 20px;
  /* position: fixed;
  bottom: 0; */
}

.home-bottom-bottom {
  padding: 0rem 1rem 2rem;
  background-color: #ffffff;
}

.home-bottom-top {
  position: relative;
  height: 27px;
  overflow: hidden;
}

.home-bottom-top:before {
  content: "";
  position: absolute;
  bottom: 0%;
  left: calc(50vw - 74px);
  width: 148px;
  height: 52px;
  border-radius: 27px;
  box-shadow: 0px 0px 0px 2000px #ffffff;
}

.logo-sm {
  width: 25px;
}

.logo {
  position: relative;
  margin-top: -20px;
  margin-bottom: -20px;
  display: flex;
  justify-content: center;
}

.h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  font-family: "Red Hat Display bold";
  color: #0c1414;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.h2 {
  font-family: "Red Hat Display bold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #0c1414;
}

.para {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.centered-text {
  text-align: center;
}

.apple-bold {
  font-weight: 400 !important;
}

.text-l {
  text-align: left;
}

.text-r {
  text-align: right;
}

.centered-button {
  display: flex;
  justify-content: center;
  padding-top: 22px;
}

.full-button {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.grey-text {
  color: #555b5b;
}

.help {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #0c1414;
}
.Toastify__close-button {
  color: #ffffff !important;
  opacity: 1 !important;
}
/* styles.css */
.custom-toast-container {
  border-radius: 8px; /* Border radius */
  z-index: 99999999999999; /* Ensure it's on top */
  box-shadow: 0px 4px 20px 0px var(--shadows-elevated, rgba(35, 31, 32, 0.12)); /* Box shadow */
}
.Toastify__toast-container {
  width: 90vw !important;
}
.tooltip-badge > span {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16.8px;
  line-height: 24px;
  background: #fafafa;
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.16);
  border-radius: 150px;
  height: 26px;
}
.Toastify__close-button {
  align-self: center !important;
  padding: 6px;
}
.Toastify__close-button > svg {
  height: 24px !important;
  width: 24px !important;
}

.got-it-button {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}

.pink-text {
  color: #f5448f;
}

/* Define the animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

/* Apply the animations */
.slide-in {
  opacity: 1;
  transform: translateY(0);
}

.slide-out {
  opacity: 0;
  transform: translateY(100%);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: add a background overlay */
  z-index: 9999;
}

@media screen and (max-width: 600px) {
  .fixed_button {
    width: 100vw;
    max-width: 600px;
    display: flex;
    justify-content: center;
  }
  .fixed_button_inner {
    width: 100vw;
  }
}

.fixed_button {
  width: 100%;
  display: flex;
  justify-content: center;
}
.fixed_button_inner {
  width: 600px;
}
